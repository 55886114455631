import Flickity from "flickity";
import "flickity/css/flickity.css";

// const flkty = new Flickity(".front-page-slider", {
//   prevNextButtons: false,
// });

const flkty = new Flickity(".frontpage-sliders--wrap", {
  prevNextButtons: false,
});

// previous
const previousButton = document.querySelector(".front-page-slider--arrow-left");
previousButton.addEventListener("click", function () {
  flkty.previous();
});
// next
const nextButton = document.querySelector(".front-page-slider--arrow-right");
nextButton.addEventListener("click", function () {
  flkty.next();
});
